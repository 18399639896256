// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
  ApiGatewayPathBuilder,
  CloudFunctionsPathBuilder,
  Env,
  IConsumerEnvironment,
  buildTenantConfig,
  envToProjectId,
  getEnvDomain,
} from 'shared-lib'

const env: Env = 'production'
const projectId = envToProjectId[env]

const apiGatewayBuilder = new ApiGatewayPathBuilder(env)
const cloudFunctionsBuilder = new CloudFunctionsPathBuilder(env)

export const environment: IConsumerEnvironment = {
  production: true,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBeP5YG7py8Hz2wTj-juIid8a0Bttpl0RQ',
    appId: '1:151805811092:web:895fb6d111a99badb5d781',
    authDomain: `${projectId}.firebaseapp.com`,
    measurementId: 'G-RDV3NCWLWT',
    messagingSenderId: '151805811092',
    projectId,
    storageBucket: `${projectId}.appspot.com`,
  },
  services: {
    auth: apiGatewayBuilder.build('auth'),
    cloudFunctions: cloudFunctionsBuilder.build(),
    commerce: apiGatewayBuilder.build('commerce'),
    content: apiGatewayBuilder.build('content'),
    customer: apiGatewayBuilder.build('customer'),
    events: apiGatewayBuilder.build('events'),
    files: apiGatewayBuilder.build('files'),
    integration: apiGatewayBuilder.build('integration'),
    messaging: apiGatewayBuilder.build('messaging'),
    notifications: apiGatewayBuilder.build('notifications'),
    product: apiGatewayBuilder.build('product'),
    productItem: apiGatewayBuilder.build('productItem'),
    registration: apiGatewayBuilder.build('registration'),
    seller: apiGatewayBuilder.build('seller'),
    user: apiGatewayBuilder.build('user'),
  },
  _business_EmailActionRoute: `https://${getEnvDomain(env, 'app.instockdepot.com')}/#/auth/actions`,
  algoliaAppId: '0LZUH6HZQG',
  algoliaSearchKey: 'ea5c5bc2cb1fb56ea951135bd8f24c06',
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  basePlatformHost: getEnvDomain(env, 'app.instockdepot.com'),
  cdnCname: getEnvDomain(env, 'content.instockdepot.com'),
  emailVerificationEmailTemplateId: '2LKYubs8e8JqMm17XrZW',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  stripePublishableKey: 'pk_live_51LLE3yJbjjSgzVBJ511NMXGTtmNw6b9xO6CtqGbSSduWeIYQ3B7Ro7QjDqJ4k7CqXVfB6Jw1oEdvo6p6LnNZw63v00rV8GTmUr',
  tenant: buildTenantConfig(env),
  tenantId: 'B2C-USER-5252c',
  themesEnabled: true,
  v2ReCaptcha: '6LdLf1MgAAAAAAo055MzMtgO9eFWG-N4PJXXrGlm',
  version: '75c5849',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
