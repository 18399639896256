import { InjectionToken } from '@angular/core'
import { IEnvironment } from './model/environment.model'

export enum COLLECTIONS {
  SELLER_APPLICATION = 'seller_application/',
}

export enum SEARCH_INDICES {
  PRODUCT_ITEM = 'product_item',
  PRODUCT_ITEM_PRICE_ASC = 'product_item_price_asc',
  PRODUCT_ITEM_PRICE_DESC = 'product_item_price_desc',
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')

export const NOTIFICATION_DROPDOWN_COUNT = 5
export const CONVERSATION_DROPDOWN_COUNT = 5

export const SELLER_ROLE = 'Seller'
export const ADMINISTRATOR_ROLE = 'Administrator'
export const CONSUMER_ROLE = 'Consumer'

export const SELLER_COMMISSION_FEE = 3.5

export const NOT_FOUND_ROUTE = '/home/404'
