// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Routing
import { AuthenticationRoutingModule } from './authentication.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Components
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { UserModule } from '@engineering11/user-web'
import { environment } from '@consumer/environments/environment'
import { IRegistrationConfig, RegistrationModule } from '@engineering11/registration-web'
import { AuthRecoveryFindAccountComponent } from './views/find-account/find-account.component'
import { AuthConfigProvider } from '@consumer/app/config/auth.config'
import { RegistrationConfigProvider } from '@consumer/app/config/registration.config'

@NgModule({
  declarations: [AuthenticationComponent, AuthenticationLoginComponent, AuthRecoveryFindAccountComponent],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    AuthModule.forRoot({ configProvider: AuthConfigProvider }),
    UserModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RegistrationModule.forRoot({ configProvider: RegistrationConfigProvider }),
  ],
  exports: [],
  providers: [],
})
export class AuthenticationModule {}
