import { Component, OnInit } from '@angular/core'
import { USER_FILTERS } from '@engineering11/user-web'
import { CONSUMER_ROLE, getCurrentToken, SELLER_ROLE, LocalStorageService, UserRolesSelector } from 'shared-lib'

export enum UserManagementTabs {
  Active = 'Active',
  Disabled = 'Disabled',
  Invited = 'Invited',
}

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  authToken?: string
  UserModeActive = USER_FILTERS.ACTIVE
  UserModeDisabled = USER_FILTERS.DISABLED

  departments: string[]
  availableRoles = UserRolesSelector
  additionalRequiredRoles = [SELLER_ROLE, CONSUMER_ROLE]

  userManagementTabs = UserManagementTabs

  //token$ = this.store.select(getCurrentToken)
  constructor(private localStorageService: LocalStorageService) {
    // TODO: add departments when we have that feature
    this.departments = []
  }

  async ngOnInit() {
    this.authToken = this.localStorageService.getItem('token')!
  }
}
