<div class="e11-container e11-mx-auto">
  <div class="e11-grid e11-grid-cols-2">
    <div class="e11-col-span-1">
      <h2 class="e11-mb-2">{{ 'Manage Users' | translate }}</h2>
      <p>{{ 'Invite and manage your team members here.' | translate }}</p>
    </div>
    <div class="e11-col-span-1 e11-justify-self-end">
      <sdk-business-user-invite
        class="e11-justify-end e11-flex"
        [authToken]="authToken"
        [departments]="departments"
        [availableRoles]="availableRoles"
        [additionalRequiredRoles]="additionalRequiredRoles"
      ></sdk-business-user-invite>
    </div>
  </div>

  <e11-panel>
    <e11-tabs [selectedTabId]="userManagementTabs.Active">
      <e11-tab [title]="userManagementTabs.Active | translate" [tabId]="userManagementTabs.Active" [contentClassOverrides]="'e11-py-4'">
        <sdk-manage-business-users
          [encodedAuthToken]="authToken"
          [userFilters]="UserModeActive"
          [departments]="departments"
          [availableRoles]="availableRoles"
        ></sdk-manage-business-users>
      </e11-tab>
      <e11-tab [title]="userManagementTabs.Invited | translate" [tabId]="userManagementTabs.Invited" [contentClassOverrides]="'e11-py-4'">
        <sdk-invited-user-list #invitedUserList [encodedAuthToken]="authToken"></sdk-invited-user-list>
      </e11-tab>
      <e11-tab [title]="userManagementTabs.Disabled | translate" [tabId]="userManagementTabs.Disabled" [contentClassOverrides]="'e11-py-4'">
        <sdk-manage-business-users
          [encodedAuthToken]="authToken"
          [userFilters]="UserModeDisabled"
          [departments]="departments"
          [availableRoles]="availableRoles"
        ></sdk-manage-business-users>
      </e11-tab>
    </e11-tabs>
  </e11-panel>
</div>
